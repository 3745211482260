<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Lobo App - Sala</span>
  </mat-toolbar-row>
</mat-toolbar>
<br>
<form action="" *ngIf="!setted">
  <mat-form-field appearance="fill">
    <mat-label>nombre</mat-label>
    <input matInput [(ngModel)]="user" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
</form>
<button mat-raised-button color="accent" (click)="setUser()" *ngIf="!setted">Enviar</button>

<br>
<div *ngIf="step1">
  <h3>Jugadores</h3>
  <mat-list role="list" >
    <mat-list-item role="listitem" *ngFor="let user of users">
      <mat-card class="fullcard">{{user.username}}</mat-card>
    </mat-list-item>

  </mat-list>
  <br>
  <button mat-raised-button color="accent" (click)="next()">Siguiente</button>

</div>


<form action="" *ngIf="step2">
  <h4 *ngIf="users">Total Jugadores: {{users.length}}</h4>
  <h3>Selecciona los personajes</h3>
  <mat-label>Vidente</mat-label>
  <mat-checkbox checked="true" disabled></mat-checkbox>
  <br>
  <mat-label>Bruja</mat-label>
  <mat-checkbox checked="true" disabled></mat-checkbox>
  <br>
  <mat-label>Cazador</mat-label>
  <mat-checkbox [(ngModel)]="cazador" [ngModelOptions]="{standalone: true}"
    (ngModelChange)="selectPersonaje($event, '')"></mat-checkbox>
  <br>
  <mat-label>Cupido</mat-label>
  <mat-checkbox [(ngModel)]="cupido" [ngModelOptions]="{standalone: true}"
    (ngModelChange)="selectPersonaje($event, '')"></mat-checkbox>
  <br>
  <mat-form-field>
    <mat-label>Lobos</mat-label>
    <select matNativeControl required (ngModelChange)="selectPersonaje($event, 'select')" [(ngModel)]="lobos"
      [ngModelOptions]="{standalone: true}">
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
    </select>
  </mat-form-field>
  <h4>Aldeanos: {{aldeanos}}</h4>
</form>
<button mat-raised-button color="accent" (click)="go()" *ngIf="step2">Enviar</button>