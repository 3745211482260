import { Component, OnInit } from '@angular/core';
import { SocketioService } from '../services/socketio.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sala',
  templateUrl: './sala.component.html',
  styleUrls: ['./sala.component.scss']
})
export class SalaComponent implements OnInit {
  user;
  setted = false;
  step1 = false;
  step2 = false;
  users;
  synth = window.speechSynthesis;
  number = 0;
  cazador = false;
  cupido = false;
  lobos = 1;
  aldeanos = 0;
  personajes = [
    'vidente',
    'bruja',
    'lobo'
  ];
  constructor(private socket: SocketioService, private router: Router) { }

  ngOnInit(): void {
    const usuario = localStorage.getItem('username');
    if (usuario) {
      this.user = usuario;
      const token = localStorage.getItem('token');
      this.socket.updateUsername(token, this.user);
      this.setted = true;
      this.step1 = true;
      this.socket.socket.emit('getuserlist');
    }

    this.socket.socket.on('mytoken', (token) => {
      localStorage.setItem('token', token);
    });


    this.socket.socket.on('userlist', (users) => {
      console.log('USERLIST', users);
      this.users = JSON.parse(users);
      this.aldeanos = this.users.length - this.personajes.length;
      for (let i = 0; i < this.aldeanos; i++) {
        this.personajes.push('aldeano');
      }
    });

    this.socket.socket.on('habla', () => {

      const cadena = 'Hola ' + localStorage.getItem('username');
      const utterThis = new SpeechSynthesisUtterance(cadena);
      // this.synth.speak(utterThis);
    });

    this.socket.socket.on('updatenext', () => {
      console.log('next');
      this.step1 = false;
      this.step2 = true;
    });

    this.socket.socket.on('updatepersonaje', (data) => {
      console.log('update personajes', data);
      const parseado = JSON.parse(data);
      this.cazador = parseado.cazador;
      this.cupido = parseado.cupido;
      this.lobos = parseInt(parseado.lobos);
    });

    this.socket.socket.on('shouldgo', () => {
      console.log('Should GO!');
      this.router.navigate(['/partida']);
    })
  }

  setUser() {
    if (this.user !== '' ) {
      this.socket.sendUsername(this.user);
      localStorage.setItem('username', this.user);
      this.setted = true;
      this.step1 = true;
      this.socket.socket.emit('getuserlist');
    } else {
      alert('Debes decirme tu nombre');
    }
  }

  next() {
    this.step1 = false;
    this.step2 = true;
    this.socket.socket.emit('next');
  }

  go() {
    if (this.aldeanos <= 0) {
      alert('No puedes comenzar la partida sin aldeanos, al menos debes dejar uno');
    } else {
      this.socket.socket.emit('go', JSON.stringify(this.personajes));
      this.router.navigate(['/partida']);
    }
  }

  selectPersonaje(event, componente) {
    console.log('Personaje seleccionado', event, componente);
    this.personajes = [
      'vidente',
      'bruja'
    ];

    if (this.cazador) {
      this.personajes.push('cazador');
    } else if (this.cupido) {
      this.personajes.push('cupido');
    }

    const objeto = {
      lobos: this.lobos,
      cazador: this.cazador,
      cupido: this.cupido
    };

    if (componente === 'select') {
      objeto.lobos = event;
      this.lobos = event;
    }

    for (let i = 0; i < this.lobos; i++) {
      this.personajes.push('lobo');
    }

    this.aldeanos = this.users.length - this.personajes.length;

    for (let i = 0; i < this.aldeanos; i++) {
      this.personajes.push('aldeano');
    }

    this.socket.socket.emit('selectpersonaje', JSON.stringify(objeto));

  }
}
