import { Component, OnInit } from '@angular/core';
import { SocketioService } from '../services/socketio.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partida',
  templateUrl: './partida.component.html',
  styleUrls: ['./partida.component.scss']
})
export class PartidaComponent implements OnInit {

  started = false;
  roundstarted = false;

  synth = window.speechSynthesis;

  me;

  personaje;
  users;

  panelOpenState = true;

  vidente = false;
  cupido = false;
  cazador = false;
  lobo = false;
  enamorado = false;
  bruja = false;
  linchar = false;
  showJugadores = true;
  videntechoiced = false;
  chatlobos = [];
  nuevalinea;
  loboquorum = false;
  pocionsalvar = true;
  pocionmatar = true;
  victima;
  pocionsalvarmarcada = false;
  pocionmatarmarcada = false;
  usersmatar = false;
  brujavictima;
  todosvotados = false;
  constructor(private socket: SocketioService, private router: Router) { }

  ngOnInit() {

    this.me = localStorage.getItem('token');

    // listados de eventos
    this.socket.socket.emit('shuffle');
    this.socket.socket.emit('getuserlist');

    // seteo la partida
    this.socket.socket.emit('gamestatus');

    // Respuestas al seteo
    this.socket.socket.on('startedpartida', (estado) => {
      this.started = estado;
    });
    this.socket.socket.on('startedronda', (estado) => {
      this.roundstarted = estado;
    });
    this.socket.socket.on('statusronda', (estado) => {
      this[estado] = true;
    });

    this.socket.socket.on('userlist', (data) => {
      console.log('Users??', data);
      this.users = JSON.parse(data);
      const token = localStorage.getItem('token');
      const persona = this.users.filter(x => x.id === token);
      console.log('Persona', persona);
      this.personaje = persona[0].personaje;
    });
    this.socket.socket.on('inicionarrador', async (message) => {
      console.log('New Message', message);
      const utterThis = new SpeechSynthesisUtterance(message);
      // await this.synth.speak(utterThis)
      this.socket.socket.emit('narradorfinish');
    });
    this.socket.socket.on('narrador', async (message) => {
      console.log('New Message', message);
      const utterThis = new SpeechSynthesisUtterance(message);
      this.synth.speak(utterThis);
    });
    this.socket.socket.on('mypersonaje', (data) => {
      console.log('mi personaje', data);
      localStorage.setItem('personaje', data);
      this.personaje = data;
    });

    // Control partida

    this.socket.socket.on('partidastart', () => {
      this.started = true;
    });

    this.socket.socket.on('roundstarted', () => {
      this.roundstarted = true;
    });
    this.socket.socket.on('updategamestatus', (data) => {
      const datos = JSON.parse(data);
      this.started = datos.started;
      this.roundstarted = datos.roundstarted;
      this[data.roundstatus] = true;
      this.pocionsalvar = datos.pocimasalvar;
      this.pocionmatar = datos.pocimamatar;
    })

    this.socket.socket.on('partidaparada', () => {
      this.started = false;
      this.roundstarted = false;
      this.router.navigate(['/salas/1']);
    });

    this.socket.socket.on('updatevotos', async () => {
      let votos = 0;
      await this.users.forEach((user) => {
        if(user.votado) {
          votos++
        }
      })
      if(votos === this.users.length - 1) {
        this.todosvotados = true;
      } 
    })

    this.socket.socket.on('finderonda', () => {
      this.roundstarted = false;
    })

    // TURNOS
    this.socket.socket.on('turnovidente', () => {
      console.log('mi personaje', this.personaje);
      if (this.personaje === 'vidente') {
        this.vidente = true;
        this.showJugadores = false;
      }
      if (this.personaje === 'vidente' && this.me.status === 'inactive') {
        
        this.socket.socket.emit('finturnovidente');
      }
    });

    this.socket.socket.on('turnolobos', () => {
      console.log('mi personaje', this.personaje);
      if (this.personaje === 'lobo') {
        this.lobo = true;
        this.showJugadores = false;
      }
    });

    this.socket.socket.on('getchat', (chat) => {
      this.chatlobos = JSON.parse(chat);
    });

    this.socket.socket.on('loboquorum', () => {
      console.log('Hay quorum');
      this.loboquorum = true;
    });

    this.socket.socket.on('turnobruja', () => {
      this.users.sort((a, b) => {
        if (a.votos > b.votos) {
          return -1;
        }
        if ( a.votos < b.votos) {
          return 1;
        }
        return 0;
      });
      this.victima = this.users[0];
      if (this.personaje === 'bruja') {
        this.bruja = true;
        this.showJugadores = false;
      }
      if (this.personaje === 'bruja' && this.me.status === 'inactive') {
        let objeto = {
          salva: null,
          mata:null
        }
        this.socket.socket.emit('finturnobruja', objeto);
      }
    });

    this.socket.socket.on('turnolinchar', () => {
      this.linchar = true;
      this.showJugadores = false;
    })
  }

  init() {
    this.socket.socket.emit('init');
  }

  start() {
    console.log('Start Round!');
    this.socket.socket.emit('startround');
  }

  fin() {
    const r = confirm('¿Estas seguro de que quieres parar la partida?');
    if (r === true) {
      this.socket.socket.emit('pararpartida');
    }
  }

  videntechoice() {
    this.videntechoiced = true;
  }

  videntecontinuar() {
    this.vidente = false;
    this.videntechoiced = false;
    this.showJugadores = true;
    this.socket.socket.emit('finturnovidente');
  }

  enviarlinea() {
    this.socket.socket.emit('nuevochat', this.nuevalinea);
    this.nuevalinea = '';
  }

  lobovote(usuario) {
    console.log('usuario', usuario);
    this.socket.socket.emit('votolobo', usuario);
  }

  lobocontinuar() {
    this.users.sort((a, b) => {
      if (a.votos > b.votos) {
        return -1;
      }
      if ( a.votos < b.votos) {
        return 1;
      }
      return 0;
    });
    console.log('Usuaris', this.users);

    this.lobo = false;
    this.showJugadores = true;
    this.socket.socket.emit('finturnolobos', this.users[0]);
  }

  usarpocion(tipo) {
    if (tipo === 'salvar') {
      let r = confirm('¿Seguro que quieres usar la poción de salvar?');
      if (r) {
        this.pocionsalvarmarcada = true;
      }
    } else if (tipo === 'matar') {
      this.usersmatar = true;
      this.pocionmatarmarcada = true;
    }
  }

  brujamata(id) {
    this.brujavictima = id;
  }

  brujacontinuar() {
    let salva = null;
    let mata = null;
    if(this.pocionsalvarmarcada) {
      salva = this.victima;
    }
    if(this.pocionmatarmarcada) {
      mata = this.brujavictima;
    }
    let objeto = {
      salva,
      mata
    }
    this.socket.socket.emit('finturnobruja', JSON.stringify(objeto));
    this.bruja = false;
    this.showJugadores = true;
  }

  votojugador(id) {
    this.socket.socket.emit('votolinchar', id);
  }

  emitirvoto() {
    this.users.sort((a, b) => {
      if (a.votos > b.votos) {
        return -1;
      }
      if ( a.votos < b.votos) {
        return 1;
      }
      return 0;
    });

    this.socket.socket.emit('finlinchar', this.users[0]);
    this.linchar = false;
    this.showJugadores = true;
  }

}
