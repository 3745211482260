<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Lobo App - Partida</span>
  </mat-toolbar-row>
</mat-toolbar>
<br>
<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Mi personaje
      </mat-panel-title>
      <mat-panel-description>
        Pulsa para ver tu personaje
      </mat-panel-description>
    </mat-expansion-panel-header>

    <img src="assets/img/{{personaje}}.png" alt="" class="imagen">

    <h4>{{personaje}}</h4>
  </mat-expansion-panel>

</mat-accordion>
<br>
<button mat-raised-button color="accent" (click)="init()" *ngIf="!started">Empezar partida</button>
<button mat-raised-button color="danger" (click)="fin()" *ngIf="started">Detener partida</button>
<br>
<br>
<div *ngIf="started">
  <button mat-raised-button color="danger" (click)="start()" *ngIf="!roundstarted">Empezar ronda</button>
</div>

<div *ngIf="showJugadores">
  <h3>Jugadores</h3>
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let user of users">
      <mat-card class="fullcard {{user.status}}" color="primary">{{user.username}}</mat-card>
    </mat-list-item>
  </mat-list>
</div>


<!-- Partes de personajes -->
<!-- Vidente -->
<div *ngIf="vidente">
  <h3>Turno vidente</h3>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let user of users" [disabled]="videntechoiced" (opened)="videntechoice()">
      <mat-expansion-panel-header *ngIf="user.status !== 'inactive' || user.id !== me">
        <mat-panel-title>
          {{user.username}}
        </mat-panel-title>
        <mat-panel-description>
          Pulsa para ver el personaje del usuario
        </mat-panel-description>
      </mat-expansion-panel-header>

      <img src="assets/img/{{user.personaje}}.png" alt="" class="imagen">

      <h4>{{user.personaje}}</h4>
    </mat-expansion-panel>

  </mat-accordion>
  <br>
  <button mat-raised-button color="accent" (click)="videntecontinuar()" *ngIf="vidente">Continuar ronda</button>
</div>

<div *ngIf="lobo">
  <h3>Turno lobos</h3>
  <mat-list role="list" *ngFor="let user of users">
    <mat-list-item role="listitem" *ngIf="user.id !== me" (click)="lobovote(user.id)">
      <mat-card class="fullcard {{user.status}}" color="primary">{{user.username}} Votos: {{user.votos}}</mat-card>
    </mat-list-item>
  </mat-list>
  <br>
  <div class="lobosChat" *ngFor="let linea of chatlobos" [innerHtml]="linea">

  </div>
  <br>
  <input type="text" [(ngModel)]="nuevalinea">
  <br>
  <button mat-raised-button color="warn" (click)="enviarlinea()" *ngIf="lobo">Enviar</button>
  <br>
  <button mat-raised-button color="accent" (click)="lobocontinuar()" *ngIf="lobo" [disabled]="!loboquorum">Continuar
    ronda</button>
</div>

<div *ngIf="bruja">
  <h3>Turno bruja</h3>
  <br>
  <p>La victima es {{victima.username}}</p>
  <br>
  <h4>Pociones</h4>
  <mat-list role="list">
    <mat-list-item role="listitem" (click)="usarpocion('salvar')">
      <mat-card class="fullcard active" color="primary" *ngIf="pocionsalvar">Poción salvar</mat-card>
    </mat-list-item>
    <mat-list-item role="listitem" (click)="usarpocion('matar')">
      <mat-card class="fullcard active" color="primary" *ngIf="pocionmatar">Poción matar</mat-card>
    </mat-list-item>
  </mat-list>
  <div *ngIf="usersmatar">
    <h3>Elije al jugador que quieres matar</h3>
    <mat-list role="list" *ngFor="let user of users">
      <mat-list-item role="listitem" *ngIf="user.id !== me" (click)="brujamata(user.id)">
        <mat-card class="fullcard {{user.status}}" color="primary">{{user.username}} Votos: {{user.votos}}</mat-card>
      </mat-list-item>
    </mat-list>
  </div>
  <br>
  <br>
  <button mat-raised-button color="accent" (click)="brujacontinuar()" *ngIf="bruja">Continuar ronda</button>
</div>

<div *ngIf="linchar">
  <h3>Turno de linchamiento</h3>
  
  <div>
    <h3>Emite tu voto</h3>
    <mat-list role="list" *ngFor="let user of users">
      <mat-list-item role="listitem" *ngIf="user.id !== me" (click)="votojugador(user.id)">
        <mat-card class="fullcard {{user.status}}" color="primary">{{user.username}} Votos: {{user.votos}}</mat-card>
      </mat-list-item>
    </mat-list>
  </div>
  <br>
  <br>
  <button mat-raised-button color="accent" (click)="emitirvoto()" *ngIf="linchar">Terminar ronda</button>
</div>

