import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  socket;

  constructor() { }

  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT);
  }

  sendUsername(username) {
    this.socket.emit('newuser', username);
  }

  updateUsername(token, username) {
    const objeto = {
      username,
      id: token
    };
    this.socket.emit('updateusername', JSON.stringify(objeto));
  }
}
