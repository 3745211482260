<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Lobo App - Salas</span>
    </mat-toolbar-row>
</mat-toolbar>
<br>
<button mat-fab class="fabbutton">
    <mat-icon>add</mat-icon>
</button>
<mat-list role="list">
    <mat-list-item role="listitem">
        <mat-card routerLink="salas/1">Simple card</mat-card>
    </mat-list-item>
    <mat-list-item role="listitem">
        <mat-card routerLink="salas/2">Simple card</mat-card>
    </mat-list-item>
    <mat-list-item role="listitem">
        <mat-card routerLink="salas/3">Simple card</mat-card>
    </mat-list-item>
  </mat-list>