import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SalaComponent } from './sala/sala.component';
import { PartidaComponent } from './partida/partida.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'salas/:id', component: SalaComponent},
  { path: 'partida', component: PartidaComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
